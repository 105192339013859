import NotificationWatcher from "app/common/watchers/NotificationWatcher";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import LogRocket from 'logrocket';
import mixpanel from "mixpanel-browser";
import useIsTestMode from "app/common/custom-hooks/useIsTestMode";
import useBusiness from "app/common/custom-hooks/useBusiness";

// Lazy load Auth and Non Auth Section of the site
const NoAuthRoute = React.lazy(() => import("./NoAuthRoute"));
const AuthenticatedRoute = React.lazy(() => import('./AuthenticatedRoute')); 

const Routes = _ => {

    const { boot, shutdown } = useIntercom();
    const {pathname} = useLocation();
    const isTestMode = useIsTestMode();
    const businessName = useBusiness()?.name;
    const token = useSelector(({auth}) => auth?.token);
    const {email, id, name, hash} = useSelector(({ settings }) => settings.profile || {}, shallowEqual);

    // Scroll to Top When Moving to another page
    useEffect(_ => {
        window?.scrollTo?.({top: 0});
    }, [pathname]);

    // Intercom Initializer
    useEffect(_ => {
        const intercomUser = {};
        if (id) intercomUser.userId = id;
        if (email) intercomUser.email = email;
        if (name) intercomUser.name = name;
        if (hash) intercomUser.userHash = JSON.parse(hash)?.w;
        boot(intercomUser);
        return shutdown;
    }, [boot, shutdown, email, id, name, hash])

    // Set Log rocket and mixpanel Identity
    useEffect(_ => {
        if (process.env.NODE_ENV === "production" && id && email && name){
            const moreUserData = {
                                "mode": isTestMode ? "Test" : "Live",
                                "Business": businessName
                            }
            LogRocket.identify(id, {email, name, ...moreUserData});
            mixpanel.identify(id);
            mixpanel.people.set({ '$name': name,
                                  '$email': email,
                                  ...moreUserData
                                });
            return _ => {
                LogRocket.identify();
                mixpanel.reset()
            }
        }
    }, [email, id, name, isTestMode, businessName]);

    return  <div data-testid="current-page" className="relative top-0">
        <NotificationWatcher />
        {token ? <AuthenticatedRoute /> : <NoAuthRoute />}
    </div>
}

export default Routes;
